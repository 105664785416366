<template>
  <div>
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-stock-management'"
        :title="'Add material to stock'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
      <v-form
        ref="form"
        lazy-validation
        class="ma-0 pt-6"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="stockItem.name"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('Name')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
              lg="3"
            >
              <v-text-field
                v-model="stockItem.code"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('Code')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
              lg="3"
            >
              <v-autocomplete
                v-model="stockItem.stock_item_category_id"
                :items="$store.getters.getStockItemCategories"
                dense
                outlined
                :label="$t('Category')"
                item-value="id"
                item-text="name"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
            >
              <v-text-field
                v-model="stockItem.price"
                v-number="number"
                :rules="[validator.required]"
                type="text"
                dense
                outlined
                :label="$t('Price')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
            >
              <v-autocomplete
                v-model="stockItem.unit"
                :rules="[validator.required]"
                :items="['kom', 'cm2']"
                dense
                outlined
                :label="$t('Unit')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
            >
              <v-text-field
                v-model="stockItem.quantity"
                v-number="number"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('Quantity')"
                type="text"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <StandardCardHeader
        :go-to-link="'admin-stock-management'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close' ) }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCellphoneKey,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEmailAlert,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import axiosIns from '@axios'
import { required } from '@core/utils/validation'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'

export default {
  components: { StandardCardHeader },
  mixins: [numberFormatMixin],
  data() {
    const snackbarMessage = ''
    const errorMessages = []
    const stockItemId = this.$route.params.item ? this.$route.params.item.id : null
    const snackbar = false
    const loading = true
    const stockItem = {
      item_id: this.$route.params.item ? this.$route.params.item.id : null,
      name: this.$route.params.item ? this.$route.params.item.name : '',
      code: this.$route.params.code ? this.$route.params.item.code : '',
      unit: this.$route.params.unit ? this.$route.params.item.unit : '',
      price: this.$route.params.price ? this.$route.params.item.price : '',
      quantity: this.$route.params.item
        ? this.$route.params.item.stock_item_items.find(el => +el.stock_id === +this.$route.params.id).quantity
        : 0,
      stock_item_category_id: this.$route.params.stock_item_category_id
        ? +this.$route.params.stock_item_category_id
        : this.$store.getters.getSelectedStockItemCategory,
    }

    return {
      snackbarMessage,
      errorMessages,
      stockItemId,
      snackbar,
      loading,
      stockItem,
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiCellphoneKey,
        mdiEmailAlert,
      },

      validator: {
        required,
      },
    }
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        axiosIns({
          method: this.stockItemId ? 'PUT' : 'POST',
          data: {
            ...this.stockItem,
            price: this.stockItem.price.replace(/,/g, ''),
            quantity: this.stockItem.quantity.replace(/,/g, ''),
          },
          url: this.stockItemId
            ? `/admin/stocks/${this.$route.params.id}/items/${this.stockItemId}`
            : `/admin/stocks/${this.$route.params.id}/items`,
        })
          .then(res => {
            this.snackbarMessage = this.$t('Data successfully saved')
            this.snackbar = true
            this.stockId = res.data.stock.id
          })
          .catch(() => {
            this.snackbarMessage = this.$t('An error occurred')
            this.snackbar = true
          })
      }
    },
  },
}
</script>
